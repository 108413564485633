var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"modal fade",attrs:{"id":"modal-form"}},[(_vm.form && _vm.tipo_servicio)?_c('div',{staticClass:"modal-dialog modal-lg"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Postular Vehículo")]),_c('button',{ref:"closeModal",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-2"},[_vm._v(" "+_vm._s(_vm.tipo_servicio.tipo_servicio == 1 ? 'Vehículo' : 'Equipo')+" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.equipo.placa),expression:"form.equipo.placa"}],staticClass:"form-control form-control-sm",class:_vm.accion == 2 ? '' :
                  _vm.$v.form.equipo_id.$invalid ? 
                  'is-invalid' : 
                  'is-valid',attrs:{"type":"text","disabled":_vm.accion == 2 ||
                  _vm.form.estado != 1},domProps:{"value":(_vm.form.equipo.placa)},on:{"change":function($event){_vm.tipo_servicio.tipo_servicio == 1 ?
                  _vm.buscarVehiculos() :
                  _vm.buscarEquipos()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form.equipo, "placa", $event.target.value)}}}),_c('div',{staticClass:"row"},[(_vm.tipo_servicio.tipo_servicio == 2 && _vm.form.equipo_placa)?_c('div',[_c('small',[_vm._v("Placa: "),_c('strong',[_vm._v(_vm._s(_vm.form.equipo_placa))])])]):_vm._e(),(_vm.tipo_servicio.tipo_servicio == 2 && _vm.form.equipo_serial)?_c('div',[_c('small',[_vm._v("Serial: "),_c('strong',[_vm._v(_vm._s(_vm.form.equipo_serial))])])]):_vm._e()])]),(_vm.tipo_servicio.tipo_servicio == 1)?_c('div',{staticClass:"form-group col-md-2"},[_vm._v(" Remolque "),(_vm.form.remolque)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.remolque.placa),expression:"form.remolque.placa"}],staticClass:"form-control form-control-sm",class:_vm.accion == 2 || _vm.tipo_servicio.tipo_vehiculo.id != 1 ? '' :
                  _vm.$v.form.remolque_id.$invalid ? 
                  'is-invalid' : 
                  'is-valid',attrs:{"type":"text","disabled":_vm.accion == 2 ||
                  _vm.form.estado != 1 ||
                  !_vm.flag_remolque},domProps:{"value":(_vm.form.remolque.placa)},on:{"change":function($event){return _vm.buscarRemolques()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form.remolque, "placa", $event.target.value)}}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"form-group",class:_vm.tipo_servicio.tipo_servicio == 1 ? 'col-md-8' : 'col-md-5'},[_vm._v(" "+_vm._s(_vm.tipo_servicio.tipo_servicio == 1 ? 'Conductor' : 'Operario')+" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.persona.numero_documento),expression:"form.persona.numero_documento"}],staticClass:"form-control form-control-sm",class:_vm.accion == 2 ? '' :
                  _vm.$v.form.persona_id.$invalid ? 
                  'is-invalid' : 
                  'is-valid',attrs:{"type":"text","disabled":_vm.accion == 2 ||
                  _vm.form.estado != 1},domProps:{"value":(_vm.form.persona.numero_documento)},on:{"change":function($event){_vm.tipo_servicio.tipo_servicio == 1 ?
                  _vm.buscarConductores() :
                  _vm.buscarOperarios()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form.persona, "numero_documento", $event.target.value)}}}),_c('small',[_vm._v(_vm._s(_vm.form.persona_name))])]),(_vm.tipo_servicio.tipo_servicio == 2)?_c('div',{staticClass:"form-group col-md-5"},[_vm._v(" Aparejador "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.aparejador.numero_documento),expression:"form.aparejador.numero_documento"}],staticClass:"form-control form-control-sm",class:_vm.accion == 2 ? '' :
                  _vm.$v.form.aparejador_id.$invalid ? 
                  'is-invalid' : 
                  'is-valid',attrs:{"type":"text","disabled":_vm.accion == 2 ||
                  _vm.form.estado != 1},domProps:{"value":(_vm.form.aparejador.numero_documento)},on:{"change":function($event){return _vm.buscarAparejadores()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form.aparejador, "numero_documento", $event.target.value)}}}),_c('small',[_vm._v(_vm._s(_vm.form.aparejador_name))])]):_vm._e()])]),(!_vm.$v.form.$invalid && _vm.accion == 1)?_c('div',{staticClass:"modal-footer justify-content-between"},[_c('div',[_c('button',{staticClass:"btn btn-sm bg-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Guardar ")])])]):_vm._e()])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }