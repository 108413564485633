<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form"
    >
      <div 
        class="modal-dialog modal-lg"
        v-if="form && tipo_servicio"
      >
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Postular Vehículo</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-2">
                {{ tipo_servicio.tipo_servicio == 1 ? 'Vehículo' : 'Equipo' }}
                <input 
                  type="text"
                  v-model="form.equipo.placa"
                  class="form-control form-control-sm"
                  :class="
                    accion == 2 ? '' :
                    $v.form.equipo_id.$invalid ? 
                    'is-invalid' : 
                    'is-valid'"
                  :disabled="
                    accion == 2 ||
                    form.estado != 1
                  "
                  @change="
                    tipo_servicio.tipo_servicio == 1 ?
                    buscarVehiculos() :
                    buscarEquipos()
                  "
                >
                <div class="row">
                  <div v-if="tipo_servicio.tipo_servicio == 2 && form.equipo_placa"><small>Placa: <strong>{{form.equipo_placa}}</strong></small></div>
                  <div v-if="tipo_servicio.tipo_servicio == 2 && form.equipo_serial"><small>Serial: <strong>{{form.equipo_serial}}</strong></small></div>
                </div>
              </div>
              <div class="form-group col-md-2" v-if="tipo_servicio.tipo_servicio == 1">
                Remolque 
                <input 
                  type="text"
                  v-if="form.remolque" 
                  v-model="form.remolque.placa"
                  class="form-control form-control-sm"
                  :class="
                    accion == 2 || tipo_servicio.tipo_vehiculo.id != 1 ? '' :
                    $v.form.remolque_id.$invalid ? 
                    'is-invalid' : 
                    'is-valid'"
                  :disabled="
                    accion == 2 ||
                    form.estado != 1 ||
                    !flag_remolque
                  "
                  @change="buscarRemolques()"
                >
              </div>
              <div class="form-group"
                :class="tipo_servicio.tipo_servicio == 1 ? 'col-md-8' : 'col-md-5' "
              >
                {{tipo_servicio.tipo_servicio == 1 ? 'Conductor' : 'Operario'}}
                <input 
                  type="text"
                  v-model="form.persona.numero_documento"
                  class="form-control form-control-sm"
                  :class="
                    accion == 2 ? '' :
                    $v.form.persona_id.$invalid ? 
                    'is-invalid' : 
                    'is-valid'"
                  :disabled="
                    accion == 2 ||
                    form.estado != 1
                  "
                  @change="
                    tipo_servicio.tipo_servicio == 1 ?
                    buscarConductores() :
                    buscarOperarios()
                  "
                >
                <small>{{form.persona_name}}</small>
              </div>
              <div class="form-group col-md-5" v-if="tipo_servicio.tipo_servicio == 2">
                Aparejador 
                <input 
                  type="text" 
                  v-model="form.aparejador.numero_documento"
                  class="form-control form-control-sm"
                  :class="
                    accion == 2 ? '' :
                    $v.form.aparejador_id.$invalid ? 
                    'is-invalid' : 
                    'is-valid'"
                  :disabled="
                    accion == 2 ||
                    form.estado != 1
                  "
                  @change="buscarAparejadores()"
                >
                <small>{{form.aparejador_name}}</small>
              </div>
            </div>
          </div>
          <div 
            class="modal-footer justify-content-between"
            v-if="!$v.form.$invalid && accion == 1"
          >
            <div>
              <button
                type="button"
                class="btn btn-sm bg-success"
                @click="save()"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import { required } from "vuelidate/lib/validators";

export default {
  name: "PostulacionAsignacion",
  components: {
    Loading,
  },
  data() {
    return {
      cargando: false,
      id: null,
      accion: 2,
      flag_remolque: false,
      tipo_servicio: {},
      form: null,
    };
  },

  validations() {
    switch (this.tipo_servicio.tipo_servicio) {
      case 1:
        if (this.tipo_servicio.tipo_vehiculo.id == 1) {
          return {
            form: {
              equipo_id: {
                required,
              },
              remolque_id: {
                required,
              },
              persona_id: {
                required,
              },
            },
          };
        } else {
          return {
            form: {
              equipo_id: {
                required,
              },
              persona_id: {
                required,
              },
            },
          };
        }
    
      case 2:
        return {
          form: {
            equipo_id: {
              required,
            },
            persona_id: {
              required,
            },
            aparejador_id: {
              required,
            },
          },
        };
    
      default:
        return {
          form: {
            equipo_id: {
              required,
            },
            remolque_id: {
              required,
            },
            persona_id: {
              required,
            },
            aparejador_id: {
              required,
            },
          },
        };
    }
  },

  methods: {
    init(data, accion){
      this.cargando = true;
      const me = this;
      this.id = data;
      this.accion = accion;
      this.flag_remolque = false;
      this.form = {
        id: null,
        cs_solicitud_nacional_tipo_servicio_id: null,
        equipo_type: null,
        equipo_id: null,
        equipo: {
          id: null,
          placa: null,
        },
        equipo_placa: null,
        equipo_serial: null,
        persona_type: null,
        persona_id: null,
        persona: {
          id: null,
          numero_documento: null,
        },
        persona_name: null,
        remolque_id: null,
        remolque: {
          id: null,
          placa: null,
        },
        aparejador_id: null,
        aparejador: {
          id: null,
          numero_documento: null,
        },
        aparejador_name: null,
        estado: 1,
      },

      axios
        .get("/api/cs/postulacionesNacionales/show/tipo_servicio/" + this.id)
        .then((response) => {
          this.cargando = false;
          this.tipo_servicio = response.data;

          this.form = {
            id: null,
            cs_solicitud_nacional_tipo_servicio_id: this.tipo_servicio.id,
            equipo_type: this.tipo_servicio.tipo_servicio == 1 ? "App\\Vehiculo" : "App\\Equipo",
            equipo_id: null,
            equipo: {
              id: null,
              placa: null,
            },
            equipo_placa: null,
            equipo_serial: null,
            persona_type: this.tipo_servicio.tipo_servicio == 1 ? "App\\Conductor" : "App\\Operario",
            persona_id: null,
            persona: {
              id: null,
              numero_documento: null,
            },
            persona_name: null,
            remolque_id: null,
            remolque: {
              id: null,
              placa: null,
            },
            aparejador_id: null,
            aparejador: {
              id: null,
              numero_documento: null,
            },
            aparejador_name: null,
            estado: 1,
          };

          if (this.tipo_servicio.postulacion) {
            const postu = this.tipo_servicio.postulacion;
            this.flag_remolque = this.tipo_servicio.tipo_vehiculo.id == 1 ? true : false;

            this.form = {
              id: postu.id,
              cs_solicitud_nacional_tipo_servicio_id: this.tipo_servicio.id,
              equipo_type: postu.equipo_type,
              equipo_id: postu.equipo_id,
              equipo: postu.equipo,
              equipo_placa: postu.equipo.placa,
              equipo_serial: postu.equipo.serial,
              persona_type: postu.persona_type,
              persona_id: postu.persona_id,
              persona: {
                id: postu.persona.id,
                numero_documento: postu.persona.numero_documento,
              },
              persona_name: postu.persona ? `${postu.persona.nombres} ${postu.persona.apellidos}` : "",
              remolque_id: this.flag_remolque ? postu.remolque_id : null,
              remolque: this.flag_remolque && postu.remolque ? 
                postu.remolque : 
                {
                  id: null,
                  placa: null,
                },
              aparejador_id: postu.aparejador_id,
              aparejador: postu.aparejador ? 
                postu.aparejador :
                {
                  id: null,
                  numero_documento: null,
                },
              aparejador_name: postu.aparejador ? `${postu.aparejador.nombres} ${postu.aparejador.apellidos}` : "",
              estado: postu.estado,
            };
          }
        })
        .catch(function(error) {
          me.cargando = false;
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    /***** Funciones para los campos de los formularios */
    async buscarVehiculos() {
      this.cargando = true;
      this.flag_remolque = false;
      let dato = this.form.equipo.placa;
      this.form.equipo = {
        id: null,
        placa: null,
      };
      this.form.equipo_id = null;
      this.form.equipo_placa = null;

      // Se reinician los datos del remolque
      this.form.remolque = {
        id: null,
        placa: null,
      };
      this.form.remolque_id = null;
      this.form.remolque_placa = null;


      if (dato) {
        let fecha = this.tipo_servicio.solicitud.fecha;
        let dias = this.tipo_servicio.tiempo;

        let res = await this.validarStatus(dato, "V", fecha, dias);
        if (res.data.length > 0) {
          //Valida la documentación del vehículo
          if (res.validacion.error) {
            this.cargando = false;
            await this.msgDocumentacion(res);
            return false;
          }

          //Valida la información del vehículo
          if (res["info"] == false) {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `Hacen falta campos por diligenciar en el formulario de creación de Vehículos`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            return false;
          }

          //Valida la línea de negocio del vehículo
          if (!res["has_lineas"]) {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `El vehículo no se encuentra en la línea de negocio Carga Seca`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            return false;
          }

          //Valida el tipo de vehículo
          if (res["data"][0].tipo_vehiculo_id != this.tipo_servicio.tipo_vehiculo.id) {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `El tipo de vehículo no coincide.`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            return false;
          }

          //Valida las postulaciones del vehículo
          if (res.postulacion) {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `El vehículo tiene postulaciones activas en otra solicitud dentro de las fechas establecidas.`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            return false;
          }
        } else {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title:
              "El vehículo digitado NO se encuentra registrado, pruebe con otra placa.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          return false;
        }

        this.$swal({
          icon: "success",
          title: "El vehículo cumple con los requisitos para poder ser postulado...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.form.equipo = {
          id: res.data[0].id,
          placa: res.data[0].placa,
        };
        this.form.equipo_id = res.data[0].id;
        this.form.equipo_placa = res.data[0].placa;

        // Se verifica si el vehículo es un cabezote
        if (res.data[0].tipo_vehiculo_id == 1) {
          this.flag_remolque = true;
        }
      }
      this.cargando = false;
    },

    async buscarEquipos() {
      this.cargando = true;
      this.flag_remolque = false;
      let dato = this.form.equipo.placa;
      this.form.equipo = {
        id: null,
        placa: null,
      };
      this.form.equipo_id = null;
      this.form.equipo_placa = null;
      this.form.equipo_serial = null;

      if (dato) {
        let fecha = this.tipo_servicio.solicitud.fecha;
        let dias = this.tipo_servicio.tiempo;

        let res = await this.validarStatus(dato, "E", fecha, dias);
        if (res.data.length > 0) {
          //Valida la documentación del vehículo
          if (res.validacion.error) {
            this.cargando = false;
            await this.msgDocumentacion(res);
            return false;
          }

          //Valida la información del equipo
          if (res["info"] == false) {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `Hacen falta campos por diligenciar en el formulario de creación de Equipo`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            return false;
          }

          //Valida la línea de negocio del equipo
          if (!res["has_lineas"]) {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `El equipo no se encuentra en la línea de negocio Carga Seca`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            return false;
          }

          //Valida las postulaciones del equipo
          if (res.postulacion) {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `El equipo tiene postulaciones activas en otra solicitud dentro de las fechas establecidas.`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            return false;
          }
        } else {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title:
              "El equipo digitado NO se encuentra registrado, pruebe con otra placa.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          return false;
        }

        this.$swal({
          icon: "success",
          title: "El equipo cumple con los requisitos para poder ser postulado...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.form.equipo = {
          id: res.data[0].id,
          placa: res.data[0].placa,
        };
        this.form.equipo_id = res.data[0].id;
        this.form.equipo_placa = res.data[0].placa;
        this.form.equipo_serial = res.data[0].serial;
      }
      this.cargando = false;
    },

    async buscarRemolques(){
      this.cargando = true;
      let dato = this.form.remolque.placa;
      this.form.remolque = {
        id: null,
        placa: null,
      };
      this.form.remolque_id = null;
      this.form.remolque_placa = null;

      if (dato) {
        let fecha = this.tipo_servicio.solicitud.fecha;
        let dias = this.tipo_servicio.tiempo;

        let res = await this.validarStatus(dato, "R", fecha, dias);
        if (res.data.length > 0) {
          //Valida la documentación del vehículo
          if (res.validacion.error) {
            this.cargando = false;
            await this.msgDocumentacion(res);
            return false;
          }

          //Valida la información del equipo
          if (res["info"] == false) {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `Hacen falta campos por diligenciar en el formulario de creación de Remolque`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            return false;
          }

          //Valida la línea de negocio del equipo
          if (!res["has_lineas"]) {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `El remolque no se encuentra en la línea de negocio Carga Seca`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            return false;
          }

          //Valida las postulaciones del equipo
          if (res.postulacion) {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `El remolque tiene postulaciones activas en otra solicitud dentro de las fechas establecidas.`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            return false;
          }
        } else {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title:
              "El remolque digitado NO se encuentra registrado, pruebe con otra placa.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          return false;
        }

        this.$swal({
          icon: "success",
          title: "El remolque cumple con los requisitos para poder ser postulado...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.form.remolque = {
          id: res.data[0].id,
          placa: res.data[0].placa,
        };
        this.form.remolque_id = res.data[0].id;
        this.form.remolque_placa = res.data[0].placa;
      }
      this.cargando = false;
    },

    async buscarConductores(){
      this.cargando = true;
      let dato = this.form.persona.numero_documento;
      this.form.persona = {
        id: null,
        numero_documento: null,
      };
      this.form.persona_id = null;
      this.form.persona_name = null;

      if (dato) {
        let fecha = this.tipo_servicio.solicitud.fecha;
        let dias = this.tipo_servicio.tiempo;

        let res = await this.validarStatus(dato, "C", fecha, dias);
        if (res.data.length > 0) {
          //Valida la documentación del vehículo
          if (res.validacion.error) {
            this.cargando = false;
            await this.msgDocumentacion(res);
            return false;
          }

          //Valida la información del equipo
          if (res["info"] == false) {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `Hacen falta campos por diligenciar en el formulario de creación de Conductor`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            return false;
          }

          //Valida la línea de negocio del equipo
          if (!res["has_lineas"]) {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `El conductor no se encuentra en la línea de negocio Carga Seca`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            return false;
          }

          //Valida las postulaciones del equipo
          if (res.postulacion) {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `El conductor tiene postulaciones activas en otra solicitud dentro de las fechas establecidas.`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            return false;
          }
        } else {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title:
              "El conductor digitado NO se encuentra registrado, pruebe con otro documento.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          return false;
        }

        this.$swal({
          icon: "success",
          title: "El conductor cumple con los requisitos para poder ser postulado...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.form.persona = {
          id: res.data[0].id,
          numero_documento: res.data[0].numero_documento,
        };
        this.form.persona_id = res.data[0].id;
        this.form.persona_name = `${res.data[0].nombres} ${res.data[0].apellidos}`;
      }
      this.cargando = false;
    },

    async buscarOperarios(){
      this.cargando = true;
      let dato = this.form.persona.numero_documento;
      this.form.persona = {
        id: null,
        numero_documento: null,
      };
      this.form.persona_id = null;
      this.form.persona_name = null;

      if (dato) {
        let fecha = this.tipo_servicio.solicitud.fecha;
        let dias = this.tipo_servicio.tiempo;

        let res = await this.validarStatus(dato, "O", fecha, dias, 1);
        if (res.data.length > 0) {
          //Valida la documentación del vehículo
          if (res.validacion.error) {
            this.cargando = false;
            await this.msgDocumentacion(res);
            return false;
          }

          // //Valida la información del equipo
          // if (res["info"] == false) {
          //   this.cargando = false;
          //   this.$swal({
          //     icon: "error",
          //     title: `Hacen falta campos por diligenciar en el formulario de creación de Operario`,
          //     toast: true,
          //     position: "top-end",
          //     showConfirmButton: false,
          //     timer: 3000,
          //     timerProgressBar: true,
          //   });
          //   return false;
          // }

          //Valida la línea de negocio del equipo
          if (!res["has_lineas"]) {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `El operario no se encuentra en la línea de negocio Carga Seca`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            return false;
          }

          //Valida las postulaciones del equipo
          if (res.postulacion) {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `El operario tiene postulaciones activas en otra solicitud dentro de las fechas establecidas.`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            return false;
          }
        } else {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title:
              "El operario digitado NO se encuentra registrado, pruebe con otro documento.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          return false;
        }

        this.$swal({
          icon: "success",
          title: "El operario cumple con los requisitos para poder ser postulado...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.form.persona = {
          id: res.data[0].id,
          numero_documento: res.data[0].numero_documento,
        };
        this.form.persona_id = res.data[0].id;
        this.form.persona_name = `${res.data[0].nombres} ${res.data[0].apellidos}`;
      }
      this.cargando = false;
    },

    async buscarAparejadores(){
      this.cargando = true;
      let dato = this.form.aparejador.numero_documento;
      this.form.aparejador = {
        id: null,
        numero_documento: null,
      };
      this.form.aparejador_id = null;
      this.form.aparejador_name = null;

      if (dato) {
        let fecha = this.tipo_servicio.solicitud.fecha;
        let dias = this.tipo_servicio.tiempo;

        let res = await this.validarStatus(dato, "O", fecha, dias, 2);
        if (res.data.length > 0) {
          //Valida la documentación del aparejador
          if (res.validacion.error) {
            this.cargando = false;
            await this.msgDocumentacion(res);
            return false;
          }

          // //Valida la información del aparejador
          // if (res["info"] == false) {
          //   this.cargando = false;
          //   this.$swal({
          //     icon: "error",
          //     title: `Hacen falta campos por diligenciar en el formulario de creación de Aparejador`,
          //     toast: true,
          //     position: "top-end",
          //     showConfirmButton: false,
          //     timer: 3000,
          //     timerProgressBar: true,
          //   });
          //   return false;
          // }

          //Valida la línea de negocio del equipo
          if (!res["has_lineas"]) {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `El aparejador no se encuentra en la línea de negocio Carga Seca`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            return false;
          }

          //Valida las postulaciones del equipo
          if (res.postulacion) {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `El aparejador tiene postulaciones activas en otra solicitud dentro de las fechas establecidas.`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            return false;
          }
        } else {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title:
              "El aparejador digitado NO se encuentra registrado, pruebe con otro documento.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          return false;
        }

        this.$swal({
          icon: "success",
          title: "El aparejador cumple con los requisitos para poder ser postulado...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.form.aparejador = {
          id: res.data[0].id,
          numero_documento: res.data[0].numero_documento,
        };
        this.form.aparejador_id = res.data[0].id;
        this.form.aparejador_name = `${res.data[0].nombres} ${res.data[0].apellidos}`;
      }
      this.cargando = false;
    },

    async validarStatus(dato, tipo, fecha, dias, tipo_operario = null) {
      let me = this;
      let url = "";
      let entidad = null;
      if (tipo == "C") {
        entidad = "Conductor";
        url = "/api/cs/postulacionesNacionales/listaConductores";
      } else if (tipo == "V") {
        entidad = "Vehiculo";
        url = "/api/cs/postulacionesNacionales/listaVehiculos";
      } else if (tipo == "R") {
        entidad = "Remolque";
        url = "/api/cs/postulacionesNacionales/listaRemolques";
      } else if (tipo == "E") {
        entidad = "Equipo";
        url = "/api/cs/postulacionesNacionales/listaEquipos";
      } else if (tipo == "O") {
        entidad = "Operario";
        url = "/api/cs/postulacionesNacionales/listaOperarios";
      }

      let params = {
        cs_solicitud_nacional_tipo_servicio_id: this.tipo_servicio.id,
        entidad,
        dato,
        fecha: fecha,
        dias: dias,
        tipo_operario: tipo_operario,
      };

      let res = null;
      await axios.get(url, { params })
        .then((response) => {
          res = response.data;
        })
        .catch(function (e) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      return res;
    },

    async msgDocumentacion(res) {
      if (res.validacion.error) {
        let title = "";
        let erroHtml = "<div>";
        if (res.validacion.error.msg) {
          title = res.validacion.error.msg;
        }
        if (res.validacion.error.documentos) {
          title = "Aviso en documentación";
          if (res.validacion.error.documentos.obligatorios) {
            erroHtml += "<b>Pendiente:</b><br>";
            erroHtml +=
              res.validacion.error.documentos.obligatorios.join("<br>");
          }
          if (res.validacion.error.documentos.vencidos) {
            erroHtml += "<br><b>Vencidos:</b><br>";
            for (
              let i = 0;
              i < res.validacion.error.documentos.vencidos.length;
              i++
            ) {
              erroHtml +=
                res.validacion.error.documentos.vencidos[i].documento +
                " - " +
                res.validacion.error.documentos.vencidos[i].fecha_vencimiento +
                "<br>";
            }
          }
          if (res.validacion.error.documentos.no_verificados) {
            erroHtml += "<br><b>No Verificados:</b><br>";
            erroHtml +=
              res.validacion.error.documentos.no_verificados.join("<br>");
          }
        }
        erroHtml += "</div>";
        await this.$swal({
          html: erroHtml,
          showCancelButton: false,
          focusConfirm: true,
          icon: "error",
          title,
          showConfirmButton: false,
          showCloseButton: true,
          footer: `
            <small style='text-align:center'>
              <b>Desde el 1 de octubre se aplicarán las restricciones</b>, 
              por favor tenga al día la documentación de sus vehículos, 
              para mas información comuniquese con la mesa de ayuda al siguiente número 
              <b>3176431376 Opción 3</b>
            </small>
          `,
        });
      }
    },
    /***** Fin - Funciones para los campos de los formularios */

    /***** Funciones de los botones de acción */
    save(){
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios({
          method: "PUT",
          url: "/api/cs/postulacionesNacionales",
          data: this.form,
        })
          .then(() => {
            this.cargando = false;
            this.$refs.closeModal.click();
            this.$parent.init();

            this.$swal({
              icon: "success",
              title: "Los datos de la postulación se guardaron exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    }
  },
};
</script>
